import * as React from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Hits, SearchBox, Stats, Pagination, Configure } from 'react-instantsearch';
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';
import ReviewCard from '../components/ReviewCard';
import './ReviewsPage.css';

const searchClient = algoliasearch('HFE30V2ZJO', '82d04b766bb390cfcfd3134896f43a10');

const ReviewsPage = () => {
    return (
        <div>
            <InstantSearch searchClient={searchClient} indexName="ct-ec-reviews">
            <Configure hitsPerPage={10} />
            <Paper elevation={4} container="div" sx={{ padding: 4, marginBottom: 2 }}>
                <SearchBox placeholder='Parc code or keywords...' searchAsYouType={true} />
                <Typography variant='subtitle1' sx={{ margin: 1}}><Stats/></Typography>
                <Pagination padding={0} />
            </Paper>
            <Hits hitComponent={ReviewCard} />
            <Paper elevation={4} container="div" sx={{ padding: 4, marginBottom: 4, marginTop: 2 }}>
                <Pagination padding={0} />
            </Paper>
            </InstantSearch>
        </div>
  );
}

export default ReviewsPage;

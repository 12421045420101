
export const SiteNavigation = [
    {
        title: 'AI-Powered Reviews',
        tag: 'reviews',
        description: 'AI generated reviews for approx. 150 parcs, based on real customer reviews sourced from Feefo.',
        slug: '/reviews',
        cta: 'Reviews'
    },
    {
        title: 'Search Suggestions',
        tag: 'search',
        description: 'Example showing how Parc codes, names and locations could be used to provide enhnaced search.',
        slug: '/suggestions',
        cta: 'Suggestions'
    }
];
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link, Outlet } from "react-router-dom";
import { green, grey } from '@mui/material/colors';
import '../App.css'

const Layout = () => {
  return (
    <>
      <div className="App">
        <AppBar position="static" sx={{ marginBottom: 4}}>
          <Toolbar sx={{backgroundColor: grey[200]}}>
            <Typography variant="h6" component="div" sx={{ color: green[800], flexGrow: 1 }}>
              Playground
            </Typography>
            <Button component={Link} to="/">Home</Button>
          </Toolbar>
        </AppBar>
        <Outlet />
      </div>
    </>
  )
};

export default Layout;

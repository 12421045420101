import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { CardActionArea } from '@mui/material';

import { SiteNavigation } from './Navigation';

export default function HomePage() {
    return (
        <Grid2 container spacing={4}>
            <Grid2 item xs={12}>
                <Typography variant="h5" textAlign="center">
                    Proof-of-Concept Playground
                </Typography>
            </Grid2>
        {SiteNavigation.map((item) => (
            <Grid2 item xs={12} sm={6} md={4} key={item.title}>
                <Card>
                    <CardActionArea component={Link} to={item.slug}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {item.tag}
                        </Typography>
                        <Typography variant="h5" component="div">
                        {item.title}
                        </Typography>
                        <Typography variant="body2">
                        {item.description}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button component={Link} to={item.slug}>{item.cta}</Button>
                    </CardActions>
                    </CardActionArea>
                </Card>
            </Grid2>
        ))}
        </Grid2>
        );
}

import * as React from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Hits, SearchBox, Configure, PoweredBy } from 'react-instantsearch';
import { Avatar, List, ListItem, Paper } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import PlaceIcon from '@mui/icons-material/Place';
import MapIcon from '@mui/icons-material/Map';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import './SuggestionsPage.css';

const searchClient = algoliasearch('HFE30V2ZJO', 'c13602c22f9f8fafd521c4d9db94e5f1');

function SelectIcon(hit) {
  const type = hit.type;
  return (
    <Avatar>
      {type.Type === 'Parc' ? <PlaceIcon/> :
      type.Type === 'Location' ? <MapIcon/>:
      type.Type === 'Place' ? <MapIcon/> :
      type.Type === 'Region' ? <TravelExploreIcon/> :
      type.Type === 'Country' ? <TravelExploreIcon/> : ''}
    </Avatar>
  );
}
function SuggestionCard(hit) {
  const description = hit.hit.Type === "Parc" ? hit.hit.Name + ' (' + hit.hit.Code + ')' :
                      hit.hit.Type === "Place" ? hit.hit.Place :
                      hit.hit.Type === "Region" ? hit.hit.Region :
                      hit.hit.Type === "Country" ? hit.hit.Country : '';
  const hint = hit.hit.Type === "Parc" ? hit.hit.Location : hit.hit.Type;
  return (
    <ListItem key={hit.hit.Code} sx={{ margin: 0, padding: 0 }} className='suggestion-list-item' style={{marginTop: 8, marginBottom: 8}}>
      <ListItemAvatar>
        <SelectIcon type={hit.hit}/>
      </ListItemAvatar>
      <ListItemText primary={description} secondary={hint} />
    </ListItem>
  );
}
const SuggestionsPage = () => {
  return (
    <div>
        <InstantSearch searchClient={searchClient} indexName="ct-ec-autocomplete">
        <Configure hitsPerPage={10} />
        <Paper elevation={4} container="div" sx={{ padding: 4, marginBottom: 2 }}>
            <SearchBox placeholder='Parc or location...' searchAsYouType={true} />
            <List component="nav" sx={{ marginLeft: 1 }}>
              <Hits hitComponent={SuggestionCard} />
            </List>
        </Paper>
        </InstantSearch>
        <PoweredBy />
    </div>
  );
}
export default SuggestionsPage;
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Card, List, Link, ListItem, CardContent, Avatar, AvatarGroup, Divider, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import { green } from '@mui/material/colors';
import PersonIcon from '@mui/icons-material/Person';
import InfoIcon from '@mui/icons-material/Info';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

export default ReviewCard;

function ReviewCard(hit) {
  const [expanded, setExpanded] = React.useState(true);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(!expanded);
  };
  return (
    <article className="gradient-border-mask" key={hit.hit.parc} style={{marginTop: 16, marginBottom: 16, marginLeft: 16, marginRight: 16}}>
      <Card elevation={0} key={hit.hit.parc}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h5">
                <Link underline='none' href={"https://www.eurocamp.co.uk/"+hit.hit.parc}>{hit.hit.parc}</Link>
              </Typography>
            </Grid>
            <Grid item xs={6}>
                <AvatarGroup max={2} total={hit.hit.reviews.length} onClick={handleChange(hit.hit.parc)}>
                    <Tooltip title={"Based on "+hit.hit.reviews.length+(hit.hit.reviews.length === 1 ? " review":" reviews")}>
                        <Avatar sx={{ bgcolor: green[600], height: 32, width: 32 }}><PersonIcon/></Avatar>
                    </Tooltip>
                </AvatarGroup>
            </Grid>
          </Grid>
          {hit.hit.chatResponse.split('\n').map((i) => (
            <Typography variant='body1' mb={2}>{i}</Typography>
          ))}
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={6} alignItems="center">
              <Typography variant="subtitle2" color="text.secondary" mt={2} alignContent="right">
                <InfoIcon fontSize='inherit'/> Review quality may vary,&nbsp;
                <Tooltip title="Click to view reviews">
                    <Link component="button" onClick={handleChange('')}>see references</Link>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography textAlign="right" variant="subtitle2" color="text.secondary" mt={2} alignContent="right">
                Powered by AI&nbsp;<AutoAwesomeIcon color='primary' fontSize='inherit' />
              </Typography>
            </Grid>
            <Grid item xs={12} hidden={expanded}>
              <Divider />
              <Typography variant="h6" mt={2} color="text.secondary" >References</Typography>
              <List>
                {hit.hit.reviews.map((review) => (
                  <ListItem dense={false} key={review.createdDate}>
                    <Typography variant="body2" color="text.secondary">
                      [{review.rating}*]&nbsp;&quot;{review.review}&quot;
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </article>
  );
}